import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Video } from './video.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ServerService {

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }

  getNumberOfVideos(): Promise<any> {
    return new Promise(resolve => {
      this.http.get('https://us-central1-otboy2.cloudfunctions.net/length').subscribe(data => {
        resolve(data);
      });
    });
  }

  getVideos(page): Promise<Array<Video>> {
    return new Promise((resolve, reject) => {
      const returnArray = new Array();
      this.http.get('https://us-central1-otboy2.cloudfunctions.net/vids?page=' + page).subscribe(data => {
        if (data[0] === undefined) { reject(); }
        for (let i = 0; i < 7; i++) {
          const video: Video = {
            _id: data[i]._id,
            link: this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + data[i].link),
            title: data[i].title
          };
          returnArray.push(video);
        }
        resolve(returnArray);
      });
    });
  }
}
