<div *ngIf="videoList.length===0">
    <p>Getting videos...</p>
    <img alt="loading" src="assets/timer.gif"/>
</div>

<div *ngIf="videoList.length!=0">
    <div *ngFor="let video of videoList; index as i" class="video">
        <p>{{video.title}}</p>
        <style>
            .embed-container { 
                position: relative; 
                padding-bottom: 50%; 
                height: 0; 
                overflow: hidden; 
                max-width: 100%; 
            } 
            .embed-container iframe, .embed-container object, .embed-container embed { 
                position: absolute; 
                top: 0; 
                left: 0; 
                width: 100%; 
                height: 100%; 
            }
        </style>
        <div class='embed-container'>
            <iframe title="video from youtube" class="e2e-iframe-trusted-src" [src]="video.link" frameborder="0" allowfullscreen="allowfullscreen"></iframe>
        </div>
        <!-- 
            <div class="ads" *ngIf="i === 2 || i === 4 ">
                <app-ad></app-ad>
            </div>
        -->
    </div>
    
    <br>
    <div class="small">
        <ngb-pagination class="d-flex justify-content-center" [collectionSize]="pageLength" [pageSize]="7" [(page)]="page" [maxSize]="3" [rotate]="true" [boundaryLinks]="false" (pageChange)="loadPage($event)"></ngb-pagination>
    </div>
    <div class="medium">
        <ngb-pagination class="d-flex justify-content-center" [collectionSize]="pageLength" [pageSize]="7" [(page)]="page" [maxSize]="5" [rotate]="true" [boundaryLinks]="false" (pageChange)="loadPage($event)"></ngb-pagination>
    </div>
    <div class="big">
        <ngb-pagination class="d-flex justify-content-center" [collectionSize]="pageLength" [pageSize]="7" [(page)]="page" [maxSize]="9" [rotate]="true" [boundaryLinks]="true" (pageChange)="loadPage($event)"></ngb-pagination>
    </div>
</div>
