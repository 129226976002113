import { Component, OnInit } from '@angular/core';
import { Video } from '../service/video.model';
import { ServerService } from '../service/server.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {
  public page = 1;
  public pageLength = 99999999;
  public videoList: Array<Video>;

  constructor(private serverService: ServerService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.videoList = new Array();
    this.serverService.getNumberOfVideos().then(numberOfVideos => {
      this.pageLength = numberOfVideos - 1;
    });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe(params => {
      if (params.has('id')) { this.page = parseInt(params.get('id'), 10); }
      if (this.page >= this.pageLength) { this.page = this.pageLength - 1; }
      if (this.page < 1) { this.page = 1; }
      this.serverService.getVideos(this.page - 1).then(videos => this.videoList = videos).catch(() => {
        this.router.navigate(['page'], { queryParams: { id: 1 } });
      });
    });
  }

  loadPage(event): void {
    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 250);
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 25);
    this.router.navigate(['page'], { queryParams: { id: event } });
  }
}
